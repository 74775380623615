<template>
  <div>
    <BannerWrapper
      v-for="(area, areaIndex) in state.areas"
      :key="areaIndex"
      :hero="areaIndex === 0"
      :multiple="area.type === 'multiple'"
    >
      <LazyBanner
        v-for="(item, itemIndex) in area.items"
        :key="itemIndex"
        :is-main-banner="areaIndex === 0 && itemIndex === 0"
        :background-video="item.video"
        :headline-tag="areaIndex === 0 && itemIndex === 0 ? 'h1' : 'h2'"
        :links="[
          {
            text: item.link_title,
            to: isValidHttpUrl(item.link) ? item.link : i18nPath(item.link),
            external: isValidHttpUrl(item.link),
          },
        ]"
        :image="item.cover"
        :image-as-background="item.settings.image_as_bg"
        :headline="item.title"
        :subheadline="item.subtitle"
        :video="item.settings.youtube_link"
        :colors="item.settings.styles"
        :overlay="item.settings.overlay"
        :position="item.settings.position"
        :is-text-shadow="item.settings.is_text_shadow"
        :text-shadows="item.settings.text_shadows"
      />
    </BannerWrapper>

    <!--    <div class="grid grid-cols-4 gap-4 py-4 bg-[#0f0f0f]  overflow-hidden ">
      <PostItem v-for="(post, postIndex) in posts" :key="postIndex" :post="post" theme="light" />
    </div>-->
    <ClientOnly>
      <noscript
        v-if="currentSiteSettings && currentSiteSettings.facebook_pixel_code"
      >
        <img
          height="1"
          width="1"
          :src="`https://www.facebook.com/tr?id=${currentSiteSettings.facebook_pixel_code}&ev=PageView&noscript=1`"
        />
      </noscript>
    </ClientOnly>
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
import { useTranslation } from "i18next-vue";

const store = useMainStore();
const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const errorRedirect = useError();

// store
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

const { $api } = nuxtApp;
const i18nPath = nuxtApp.$i18nPath;

const blocks = ref(null);
const state = reactive({
  areas: [],
  posts: [],
});

try {
  const { data, error } = await useAPI("/api/front/blocks");
  console.log(data);
  blocks.value =
    data._rawValue && data._rawValue.data ? data._rawValue.data : null;
  state.areas = blocks.value ? blocks.value.areas : [];
} catch (error) {
  console.log(error);
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

useHead({
  title: t("common.home_title"),
});
</script>
